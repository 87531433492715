import React from "react"

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="footer">
      <div className="footer-center">
        <p>Reivew Rapidly copyright {year}</p>
      </div>
    </footer>
  )
}

export default Footer
