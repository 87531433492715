import React, { useState, useContext, useEffect } from "react"
import Client from "../Contentful"

const GlobalContext = React.createContext()

const GlobalProvider = ({ children }) => {
  // State
  const [currentStep, setCurrentStep] = useState(0)
  const [data, setData] = useState()
  const [umbrella, setUmbrella] = useState({
    policiesExtended: [],
    excessLiability: "",
    personalInjury: "",
  })

  // Scroll to Top

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  // Get Data
  const getData = async () => {
    try {
      let response = await Client.getEntries({
        content_type: "reviewRapidly",
        order: "sys.createdAt",
      })
      let coverage = formatData(response.items, "umbrella")
      setData(coverage)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const formatData = (items, filter) => {
    let data = items
      .map(item => {
        let id = item.sys.id
        let temp = { ...item.fields, id }
        return temp
      })
      .filter(item => item.temp)
      .filter(item => item.temp.includes(filter))

    return data
  }

  // handleChange
  const handleChange = e => {
    let name = e.target.name
    let value = e.target.value
    let newData = umbrella

    newData[name] = value
    setUmbrella({ ...newData })
  }

  // handle Checkbox
  const handleCheckboxChange = e => {
    let checked = e.target.checked
    let name = e.target.name
    let value = e.target.value
    let newData = umbrella

    if (checked) {
      newData[value] = [...newData[value], name]
      setUmbrella(newData)
    } else {
      newData[value] = newData[value].filter(item => item !== name)
      setUmbrella(newData)
    }
  }
  // handleChange drivers
  const handleChangeDriver = e => {
    // let index = e.target.id
    // let name = e.target.name
    // let value = e.target.value
    // let newData = drivers
    // newData[index][name] = value
    // setDrivers([...newData])
  }

  const handleLogoClick = () => {
    window.location.reload()
    setCurrentStep(0)
  }

  // Form Steps

  // next step in form
  const handleNextStep = newData => {
    // let newVehicleData = vehicles.map((vehicle, index) =>
    // Object.assign(vehicle, newData[index])
    // )

    // setVehicles(newVehicleData)
    setCurrentStep(prev => prev + 1)
    scrollToTop()
  }
  // next step in form
  const handleNextCoverageStep = newData => {
    // setAllCoverage(newData)
    setCurrentStep(prev => prev + 1)
    scrollToTop()
  }

  // previous step in form
  const handlePrevStep = newData => {
    // setVehicles(prev => [...prev, newData])
    setCurrentStep(prev => prev - 1)
    scrollToTop()
  }
  // form submit
  const handleSubmit = formData => {
    console.log(formData)
  }

  return (
    <GlobalContext.Provider
      value={{
        data,
        currentStep,
        handleNextStep,
        handlePrevStep,
        handleSubmit,
        handleChangeDriver,

        handleNextCoverageStep,
        handleCheckboxChange,

        umbrella,
        handleChange,
        handleLogoClick,
        scrollToTop,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export { GlobalContext, GlobalProvider }
