import React from "react"
import Login from "./Login"
import Logout from "./Logout"
import { useAuth0 } from "@auth0/auth0-react"
// import { useGlobalContext } from "../../context/auto_context"
import { Link } from "gatsby"

const Navbar = () => {
  const { isAuthenticated } = useAuth0()
  // const { handleLogoClick } = useGlobalContext()

  return (
    <nav className="nav">
      <div className="nav-center">
        <header className="nav-header">
          <h1 className="nav-title">
            <span className="logo-title">
              <Link to="/" className="link logo">
                Review Rapidly
              </Link>
            </span>
          </h1>
        </header>
        <div className="login-container">
          {isAuthenticated ? <Logout /> : <Login />}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
